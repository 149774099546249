<template>
    <el-dialog title="总体工程进度节点" :close-on-click-modal="false" :visible.sync="visible" width="500px">
        <el-row class="set_border">
            <el-col :span="8">
                <div class="majTitle1">总体节点名称</div>
            </el-col>
            <el-col :span="16">
                <div class="majTitle3">
                    <el-input class="sp-input1" v-model="dataForm.nodeName"></el-input>
                </div>
            </el-col>
        </el-row>
        <div  class="majTitle2">分项节点</div>
        <el-row style="border: solid #dadada 1px;margin-top:5px">
            <el-button v-preventReClick class="btn" style="margin:5px 10px 5px 10px" type="primary" size="small" :disabled="updateFlg || addFlg"
                    @click="addNodeSetHandle()"> 新增</el-button>
        </el-row>
        <el-row style="margin-top: 10px;">
            <el-table ref="dataListTable" class="dataListTable" :data="dataForm.nodeList" header-align="center" border
                :cell-style="{ padding: '0px' }" header-cell-class-name="dataListTHeader" style="margin-bottom: 20px;margin-top:-12px">
                <el-table-column label="NO." prop="sort" width="80" align="center">
                    <template slot-scope="scope">
                        <span v-if="!getDisabled(scope.$index)">{{ scope.row.sort }}</span>
                        <el-input class="sp-input" v-if="getDisabled(scope.$index)" v-model="scope.row.sort"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="分项节点名称" prop="subNodeName" width="259" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="!getDisabled(scope.$index)">{{ scope.row.subNodeName }}</span>
                        <el-input class="sp-input" v-if="getDisabled(scope.$index)"
                            v-model="scope.row.subNodeName"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button v-preventReClick type="text" size="small" class="min_btn" v-if="!updateFlg && !addFlg"
                            @click="updateNodeSetHandle(scope.$index, scope.row)">修改</el-button>
                        <el-button v-preventReClick type="text" size="small" class="min_btn" v-if="!updateFlg && !addFlg"
                            @click="deleteHandle(scope.$index)">删除</el-button>
                        <!--新增时按钮-->
                        <el-button v-preventReClick type="text" size="small" @click="saveHandle()"
                            v-if="getDisabled(scope.$index) && addFlg">保存</el-button>
                        <el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
                            v-if="getDisabled(scope.$index) && addFlg">取消</el-button>
                        <!--修改时按钮-->
                        <el-button v-preventReClick type="text" size="small" @click="updateHandle()"
                            v-if="getDisabled(scope.$index) && updateFlg">保存</el-button>
                        <el-button v-preventReClick type="text" size="small" @click="cancelUpdateHandle()"
                            v-if="getDisabled(scope.$index) && updateFlg">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="formBtnGroup">
            <el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
                @click="dataFormSubmit()">保存</el-button>
        </el-row>
    </el-dialog>
</template>

<script>

import $common from "@/utils/common.js"
export default {
    data() {
        return {
            visible: false,
            dataForm: {
                workNo: '',
                nodeName: '',
            },
            updateIndex: '',
            updateFlg: false,
            addIndex: '',
            addFlg: false,
            dataRule: {
            },
            updateData: {
                sort: 0,
                subNodeName: '',
            }
        }
    },
    computed: {
    },
    methods: {
        getDisabled(index) {
            if (this.addIndex === '' && this.updateIndex === '') {
                return false;
            } else if (this.addIndex === index && this.addIndex !== '') {
                return true;
            } else if (this.updateIndex === index && this.updateIndex !== '') {
                return true;
            } else {
                return false;
            }
        },
        init(nodeId) {
            this.dataForm = {
                workNo: '',
                nodeName: '',
                nodeList: [
                ]
            }
            this.dataForm.workNo = $common.getItem("workNo");
            this.dataForm.nodeId = nodeId ? nodeId : ""
            this.visible = true;
            this.updateIndex = ''
            this.updateFlg = false
            this.addFlg = false
            if (nodeId != '' && nodeId != undefined) {
                this.getInfo()
            } 
        },
        getInfo() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectprogressnodeset/info/" + this.dataForm.nodeId,
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body
                }
            });
        },
        updateNodeSetHandle(index, nodeSet) {
            this.updateFlg = true;
            this.updateIndex = index;
            this.updateData = {
                sort: nodeSet.sort,
                subNodeName: nodeSet.subNodeName,
            }
        },
        saveHandle() {
            this.addFlg = false;
            this.addIndex = '';
        },
        cancelSaveHandle() {
            let nodeList = [];
            for (var i = 0; i < this.dataForm.nodeList.length; i++) {
                if (i != this.addIndex) {
                    nodeList.push(this.dataForm.nodeList[i]);
                }
            }
            this.dataForm.nodeList = nodeList;
            this.addFlg = false;
            this.addIndex = '';
        },
        updateHandle() {
            this.updateFlg = false;
            this.updateIndex = '';
        },
        cancelUpdateHandle() {
            for (var i = 0; i < this.dataForm.nodeList.length; i++) {
                if (i == this.updateIndex) {
                    this.dataForm.nodeList[i].sort = this.updateData.sort
                    this.dataForm.nodeList[i].subNodeName = this.updateData.subNodeName
                }
            }
            this.updateFlg = false;
            this.updateIndex = '';
        },
        addNodeSetHandle() {
            let nodeList = this.dataForm.nodeList;
            let maxSort = 1;
            if (nodeList != undefined && nodeList.length > 0) {
                for (var i = 0; i < nodeList.length; i++) {
                    if (maxSort < parseInt(nodeList[i].sort)) {
                        maxSort = parseInt(nodeList[i].sort);
                    }
                }
                maxSort = maxSort + 1;
            }
            let sub = {
                nodeId: this.dataForm.nodeId,
                subNodeName: '',
                subNodeId: '',
                sort: maxSort,
            }
            this.dataForm.nodeList.push(sub);
            this.addFlg = true;
            this.addIndex = this.dataForm.nodeList.length - 1
        },
        deleteHandle(index) {
            this.dataForm.nodeList.splice(index, 1);
        },
        dataFormSubmit() {
            if(this.dataForm.nodeName == '' || this.dataForm.nodeName == undefined) {
                this.$message.error("请输入总体节点名称")
                return;
            }

            let _url = "/business/projectprogressnodeset/save";
            if (this.dataForm && this.dataForm.nodeId != '') {
                _url = "/business/projectprogressnodeset/update";
            }
            this.$http({
                url: this.$store.state.httpUrl + _url,
                method: "post",
                data: this.$http.adornData(this.dataForm)
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.visible = false;
                            this.$emit("refreshDataList");
                        }
                    });
                }
            });
        }
    }
}
</script>
<style scoped>
.sp-input /deep/ .el-input__inner {
    width: 100% !important;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.item::v-deep .el-form-item__label {
    color: #00428e !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: rgb(211, 230, 248);
    white-space: nowrap !important;
    text-align: center !important;
}

.set_border {
    border: solid #dadada 1px;
}

.set_border1 {
    border: solid #dadada 1px;
}

.sp-input1 {
    margin-top: 2px;
    width: 240px !important;
}

.majTitle1 {
    background-color: #E1F1FF;
    padding-left: 35px;
    font-weight: bolder;
    color: #00428e !important;
    height: 45px;
    padding-top: 14px;
    border-right: solid #dadada 1px;
}

.majTitle2 {
    margin-top: 10px;
    background-color: #E1F1FF;
    padding-left: 45px;
    font-weight: bolder;
    color: #00428e !important;
    height: 40px;
    width: 146px;
    padding-top: 12px;
    border: solid #dadada 1px;
}

.majTitle3 {
    padding-left: 15px;
    font-weight: bolder;
    color: #00428e !important;
    height: 45px;
    border-left: 0px;
}
</style>
